<template>
	<div class="flex" style="flex-direction: column; height: 100%">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="查看企业：">
							<el-select v-model="searchParm.entId" clearable :popper-append-to-body="false" placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right"><el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px">新增绑定</el-button></div>
				<div class="title-right"><el-button type="primary" size="medium" @click="add()" style="margin-right: 8px">新增wincc测点</el-button></div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px"
						>
							<el-table-column prop="id" align="center" label="绑定id" show-overflow-tooltip></el-table-column>
							<el-table-column prop="entName" align="center" label="企业名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="measId" align="center" label="测点id" show-overflow-tooltip></el-table-column>
							<el-table-column prop="measName" align="center" label="测点名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="id" align="center" label="wincc传感器id" show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="wincc传感器名称" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{
										scope.row.currentMqttPointDesc+'-'+scope.row.speedMqttPointDesc
									}}</div>
								</template>
								
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<!-- <el-button type="text" @click="editName(scope.row)">编辑</el-button> -->
									<el-button type="text" @click="remove(scope.row)">删除绑定</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.page"
						:page-size="searchParm.pageSize"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>
		<insert :isShow.sync="showInsertDialog" :dialogObj="insertObj"></insert>
		<addWincc :isShow.sync="showAddDialog" :dialogObj="addObj"></addWincc>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import insert from './insert.vue';
import addWincc from './add.vue';
export default {
	components: {
		smCard,
		insert,
		addWincc
	},
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				pageSize: 20,
				page: 1
			},
			showInsertDialog: false,
			insertObj: {},
			showAddDialog:false,
			addObj: {},
			showEditDialog: false,
			editObj: {},
			showCopyInsertDialog: false,
			copyInsertObj: {},

			ents: []
		};
	},
	created() {
		this.getEnts();
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//获取企业列表
		getEnts() {
			this.$get('/backend-api/sys/ent/list', {
				size: 99
			}).then(res => {
				if (res.code == 1000) {
					this.ents = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				pageSize: 20,
				page: 1
			};
			this.list();
		},
		//列表数据
		list() {
			this.$get('/backend-api/eqp-set-unit-meas-mqtt/boundListByPage', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//打开新增弹窗
		insert() {
			this.insertObj['type'] = 1;
			this.showInsertDialog = true;
		},
		//打开新增弹窗
		editName(row) {
			this.insertObj['row'] = row;
			this.insertObj['type'] = 2;
			this.showInsertDialog = true;
		},
		//打开编辑弹窗
		edit(row) {
			this.editObj = row;
			this.showEditDialog = true;
		},
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.page = val;
			this.list();
		},
		
		//打开新增wincc测点
		add() {
			this.addObj['type'] = 1;
			this.showAddDialog = true;
		},
		
		//删除
		remove(row) {
			this.$confirm('是否确认删除？', '删除绑定', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$postJson('/backend-api/eqp-set-unit-meas-mqtt/unBinding', {
						id: row.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		}
	},
	mounted() {
		this.list();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
